/*Banner Section Starts Here*/
.banner-content {
    max-width: 570px;
    border: 4px solid $white-color;
    background: $theme-two;
    padding: 50px 50px 55px;
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
    @include border-radius(70px 15px);

    .cate {
        font-weight: 400;
        margin: 0;
        margin-bottom: 6px;
    }

    .title {
        font-size: 60px;
        line-height: 1;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 34px;
        font-size: 18px;
        line-height: 30px;
    }

    .custom-button {
        &::before {
            background: $theme-one;
        }

        &::after {
            background: $theme-five;
        }
    }

    @include breakpoint(max-md) {
        padding: 50px 30px;

        .cate {
            font-size: 24px;
        }

        .title {
            font-size: 50px;
            line-height: 60px;
        }

        p {
            font-size: 16px;
            line-height: 28px;
        }
    }

    @include breakpoint(max-sm) {
        padding: 40px 0;
        background: transparent;
        // margin: 0 -15px;
        box-shadow: none;
        border: none;

        .cate {
            font-size: 20px;
        }

        .title {
            font-size: 42px;
            line-height: 48px;
        }
    }

    &.style-2 {
        border: none;
        background: transparent;
        box-shadow: none;
        padding: 0;
        max-width: 500px;
    }
}

.banner-thumb {
    width: calc(100% - 610px);
    position: relative;

    img {
        position: absolute;
        top: -90px;
        left: 0;
    }

    @include breakpoint(max-xl) {
        width: calc(100% - 560px);

        img {
            width: 550px;
            top: -40px;
        }
    }
}

.banner-section {
    padding: 180px 0;
    position: relative;

    @include breakpoint(max-xl) {
        padding: 150px 0;
    }

    @include breakpoint(max-lg) {
        padding: 120px 0;
    }

    @include breakpoint(max-md) {
        padding: 100px 0;
        background: $theme-two !important;
    }

    @include breakpoint(max-sm) {
        padding: 80px 0 90px;
    }

    &.style-2 {
        padding: 236px 0 245px;

        @include breakpoint(max-xl) {
            padding: 180px 0;
        }

        @include breakpoint(max-lg) {
            padding: 170px 0;
        }

        @include breakpoint(max-md) {
            padding: 150px 0;
            background-color: $theme-four !important;
        }

        @include breakpoint(max-sm) {
            padding: 120px 0 130px;
        }
    }

    &.style-slide {
        padding: 0;
    }
}

.banner-item {
    padding: 180px 0;
    position: relative;

    @include breakpoint(max-xl) {
        padding: 150px 0;
    }

    @include breakpoint(max-lg) {
        padding: 120px 0;
    }

    @include breakpoint(max-md) {
        padding: 100px 0;
    }

    @include breakpoint(max-sm) {
        padding: 80px 0 90px;
    }

    &.slick-current {

        .banner-item-content {
            transform: scale(1);
            opacity: 1;
        }
    }

    &-content {
        transform: scale(.5);
        opacity: 0;
        transition: all .6s linear;

        h1 {
            line-height: 1.2;
            margin-bottom: 20px;
        }

        p {
            color: $title-color;
            margin-bottom: 40px;
        }
    }

}

.top-shape-content {
    position: absolute;
    top: 0;
    right: calc(50% - 585px);

    @include breakpoint(max-xl) {
        right: calc(50% - 460px);

        img {
            max-width: 400px;
        }
    }

    img {
        animation: banner-imgskew 5s linear infinite;
    }
}

.slick-slide.slick-active.slick-current {
    .banner-item-content {
        transform: scale(1);
        opacity: 1;
    }
}