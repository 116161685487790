// BORDER RADIUS
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}
@mixin border-top-left-radius($radius) {
    -webkit-border-top-left-radius: $radius;
    -moz-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
}
@mixin border-top-right-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    -moz-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
}
@mixin border-bottom-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
}
@mixin border-bottom-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
}
//   transition 
@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
}
//  transform property
@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}
@mixin breakpoint($point) {
    @if $point==sm {
        @media (min-width: 576px) {
            @content ;
        }
    }
    @else if $point==max-sm {
        @media (max-width: 575px) {
            @content ;
        }
    }
    @else if $point==md {
        @media (min-width: 768px) {
            @content ;
        }
    }
    @else if $point==max-md {
        @media (max-width: 767px) {
            @content ;
        }
    }
    @else if $point==lg {
        @media (min-width: 992px) {
            @content ;
        }
    }
    @else if $point==max-lg {
        @media (max-width: 991px) {
            @content ;
        }
    }
    @else if $point==xl {
        @media (min-width: 1200px) {
            @content ;
        }
    }
    @else if $point==max-xl {
        @media (max-width: 1199px) {
            @content ;
        }
    }
    @else if $point==xxl {
        @media (min-width: 1400px) {
            @content ;
        }
    }
    @else if $point==max-xxl {
        @media (max-width: 1399px) {
            @content ;
        }
    }
}

//Gadient Color Of Text
@mixin gradient-color($gradient-color) {
    background: $gradient-color;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}