/*Header Section Starts Here*/
header {
    position: relative;
    z-index: 99;
    @include transition($transition);
    &.header-fixed {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        animation: fadeInDown .5s;
        -webkit-animation: fadeInDown .5s;
        -moz-animation: fadeInDown .5s;
        box-shadow: 0 0 10px rgba($color:$title-color, $alpha:.1);
        border-bottom: 1px solid $border-color;
        @include breakpoint(lg) {
            top: -46px;
        }
        @include breakpoint(xl) {
            top: -50px;
        }
    }
}
.header-top {
    background: $theme-color;
    * {
        color: $white-color;
    }
    @include breakpoint(max-lg) {
        display: none;
    }
    &.open {
        display: block;
    }
}
.header-top-area {
    @extend %flex;
    justify-content: space-between;
    align-items: center;
    .social-icons {
        margin: 0;
        li {
            padding: 0;
            border-right: 1px solid #a852a5;
            a {
                height: 50px;
                line-height: 50px;
                width: 50px;
                background: transparent;
                @include border-radius(0);
                text-align: center;
            }
            &:first-child {
                border-left: 1px solid #a852a5;
            }
        }
    }
    .left {
        @extend %flex;
        margin: 0 -20px;
        li {
            padding: 11px 20px;
            border-right: 1px solid #a852a5;
            &:last-child {
                border-right: none;
            }
            i {
                margin-right: 5px;
            }
        }
    }
    @include breakpoint(max-xl) {
        .left {
            li {
                font-size: 14px;
                padding: 9px 15px;
            }
        }
        .social-icons {
            li {
                a {
                    height: 46px;
                    line-height: 46px;
                    width: 46px;
                }
            }
        }
    }
    @include breakpoint(max-lg) {
        .left {
            width: 100%;
            text-align: center;
            justify-content: center;
            margin: 0;
            border: 1px solid #a852a5;
            li {
                flex-grow: 1;
                border-top: 1px solid #a852a5;
            }
        }
        .social-icons {
            width: 100%;
            text-align: center;
            justify-content: center;
            margin: 0;
            border-bottom: 1px solid #a852a5;
            li {
                flex-grow: 1;
            }
        }
    }
}
.header-bottom {
    background: $white-color;
    position: relative;
}
.header-wrapper {
    @extend %flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint(sm) {
        position: relative;
    }
    .logo {
        // width: 147px;
        a {
            display: block;
            img {
                max-width: 100%;
            }
        }
        @include breakpoint(max-sm) {
            width: 130px;
        }
        @include breakpoint(max-xl) {
            @include breakpoint(lg) {
                width: 140px;
            }
        }
    }
    .menu-area {
        @extend %flex;
        align-items: center;
        .search-button {
            margin: 0 20px;
            a {
                width: 50px;
                line-height: 50px;
                @include border-radius(50%);
                border: 1px solid #f3f3f3;
                text-align: center;
                color: $theme-color;
            }
        }
        .cart-button {
            padding: 24px 20px;
            border-left: 1px solid #f3f3f3;
            border-right: 1px solid #f3f3f3;
            position: relative;
            a {
                .cart-amount {
                    position: absolute;
                    top: 27px;
                    left: 50%;
                    @include transform(translateX(-50%));
                    background: $theme-color;
                    height: 24px;
                    width: 24px;
                    line-height: 24px;
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    color: $white-color;
                    @include border-radius(50%);
                }
                i {
                    font-size: 22px;
                    color: $title-color;
                    margin-top: 24px;
                }
            }
        }
        @include breakpoint(max-xl) {
            .search-button {
                margin: 0 15px;
            }
            .cart-button {
                a {
                    .cart-amount {
                        top: 15px;
                    }
                    i {
                        margin-top: 16px;
                    }
                }
            }
            .search-button {
                a {
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                }
            }
        }
        .ellepsis-bar {
            font-size: 20px;
            margin-left: 30px;
        }
        @include breakpoint(max-lg) {
            .cart-button {
                border: none;
                padding: 24px 25px;
                a {
                    .cart-amount {
                        top: 20px;
                    }
                }
            }
            .search-button {
                margin: 0;
            }
        }
        @include breakpoint(max-sm) {
            padding: 15px 0;
            .search-button {
                padding: 0;
                a {
                    width: unset;
                    height: 40px;
                    border: none;
                }
            }
            .ellepsis-bar {
                margin-left: 20px;
            }
            .cart-button {
                padding: 10px 20px;
                border: none;
                a {
                    i {
                        font-size: 16px;
                    }
                    .cart-amount {
                        top: 12px;
                        font-size: 12px;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
.menu {
    @extend %flex;
    >li {
        >a {
            color: $theme-color;
            font-size: 14px;
            font-weight: 700;
            padding: 15px 22px;
            text-transform: uppercase;
            i {
                margin-right: 5px;
            }
        }
        &:nth-of-type(6n + 1) {
            >a {
                color: $theme-two;
            }
        }
        &:nth-of-type(6n + 3) {
            >a {
                color: $theme-three;
            }
        }
        &:nth-of-type(6n + 4) {
            >a {
                color: $theme-four;
            }
        }
        &:nth-of-type(6n + 5) {
            >a {
                color: $theme-five;
            }
        }
        &:nth-of-type(6n + 6) {
            >a {
                color: $theme-six;
            }
        }
    }
    @include breakpoint(lg) {
        li {
            position: relative;
            .submenu {
                position: absolute;
                top: calc(100%);
                left: 0;
                -webkit-transition: all ease 0.3s;
                -moz-transition: all ease 0.3s;
                transition: all ease 0.3s;
                z-index: 9;
                background: #ffffff;
                width: 220px;
                padding: 0;
                -webkit-transform: translateY(35px);
                -ms-transform: translateY(35px);
                transform: translateY(35px);
                opacity: 0;
                visibility: hidden;
                border: none;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
                li {
                    padding: 0;
                    a {
                        padding: 10px 20px;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;
                        color: $title-color;
                        font-weight: 500;
                        border-bottom: 1px solid $border-color;
                        &:hover, &.active {
                            background: $theme-color;
                            padding-left: 22px;
                            color: $white-color;
                        }
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                    .submenu {
                        left: 100%;
                        top: 0;
                    }
                }
            }
            &:hover {
                > .submenu {
                    -webkit-transform: translateY(0);
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                    opacity: 1;
                    display: block;
                    visibility: visible;
                }
            }
        }
        @include breakpoint(max-xl) {
            li {
                a {
                    padding: 10px 12px;
                }
            }
        }
    }
    @include breakpoint(max-lg) {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background: $white-color;
        overflow: auto;
        max-height: 400px;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        transition: all ease 0.3s;
        -webkit-transform: scaleY(0);
        -ms-transform: scaleY(0);
        transform: scaleY(0);
        transform-origin: top;
        li {
            width: 100%;
            padding: 0;
            a {
                display: block;
                padding: 10px 25px;
                font-size: 15px;
                font-weight: 700;
                text-transform: capitalize;
                border-top: 1px solid #e5e5e5;
                color: $title-color;
                &:hover {
                    background: $body-bg;
                }
            }
            .submenu {
                padding-left: 20px;
                display: none;
                padding-block: 0;
                position: static !important;
                border: none;
                transform: translate3d(0px, 0px, 0px) !important;
                li {
                    width: 100%;
                    a {
                        font-size: 14px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
                &.show {
                    display: block !important;
                }
            }
        }
        &.active {
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
}
.header-bar {
    position: relative;
    cursor: pointer;
    width: 25px;
    height: 20px;
    span {
        position: absolute;
        display: inline-block;
        height: 3px;
        width: 100%;
        @include transition($transition);
        background-color: $title-color;
        left: 0;
        &:first-child {
            top: 0;
        }
        &:nth-child(2) {
            top: 52%;
            transform: translateY(-65%);
        }
        &:last-child {
            bottom: 0;
        }
    }
    &.active {
        span {
            &:first-child {
                @include transform(rotate(45deg) translate(3px, 9px));
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:last-child {
                @include transform(rotate(-45deg)translate(3px, -9px));
            }
        }
    }
    @include breakpoint(max-sm) {
        width: 15px;
        height: 16px;
        span {
            height: 2px;
            width: 20px;
        }
        &.active {
            span {
                &:first-child {
                    @include transform(rotate(45deg) translate(4px, 6px));
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child {
                    @include transform(rotate(-45deg)translate(4px, -6px));
                }
            }
        }
    }
}
.menu-item-has-children {
    >a {
        &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: 600;
            content: "\f0d7";
            margin-left: 3px;
        }
    }
}
.header-form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 999;
    visibility: hidden;
    @extend %flex;
    justify-content: center;
    align-items: center;
    @include transition($transition);
    &.active {
        visibility: visible;
        opacity: 1;
    }
    .bg-lay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: $title-color, $alpha:.8);
        cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
        .cross {
            position: absolute;
            color: $white-color;
            cursor: pointer;
            right: 15px;
            top: 15px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: $theme-color;
        }
    }
    .form-container {
        margin: 15px;
        max-width: 700px;
        width: 100%;
        @extend %flex;
        padding: 30px;
        background: $title-color;
        position: relative;
        z-index: 1;
        button {
            width: 120px;
            height: 60px;
            @include border-radius(0);
            background: $theme-color;
            border: none;
            outline: none;
            font-weight: 700;
            color: $white-color;
            text-transform: uppercase;
        }
        input {
            width: calc(100% - 120px);
            height: 60px;
            @include border-radius(0);
            border: none;
            outline: none;
            padding: 20px;
        }
        @include breakpoint(max-sm) {
            padding: 1px;
            margin: 10px;
            button {
                width: 100px;
                font-size: 14px;
                font-weight: 400;
            }
            input {
                width: calc(100% - 100px);
            }
        }
    }
}