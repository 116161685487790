.container {
    @include breakpoint(xl) {
        max-width: 1200px;
    }
}
%pr {
    position: relative;
}
%pa {
    position: absolute;
    content : '';
}
%flex {
    display: flex;
    flex-wrap: wrap;
}
%center {
    top: 50%;
    left: 50%;
    @include transform(translate(-50% , -50%));
    position: absolute;
    content: "";
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-50 {
    margin-top: 50px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb--10 {
    margin-bottom: -10px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb--50 {
    margin-bottom: -50px;
}
.mb--40--50 {
    margin-bottom: -40px;
    @media screen and (min-width: 400px) {
        margin-bottom: -50px;
    }
}
.pt-10 {
    padding-top: 10px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-30 {
    padding-top: 30px;
}
.pt-40 {
    padding-top: 40px;
}
.pt-50 {
    padding-top: 50px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pb-40 {
    padding-bottom: 40px;
}
.pb-50 {
    padding-bottom: 50px;
}
.mb-30-none {
    margin-bottom: -30px;
}
.padding-top {
    padding-top: 80px;
}
.padding-bottom {
    padding-bottom: 80px;
}
.padding-top-2 {
    padding-top: 40px;
}
.padding-bottom-2 {
    padding-bottom: 40px;
}
@include breakpoint(lg) {
    .padding-top {
        padding-top: 120px;
    }
    .padding-bottom {
        padding-bottom: 120px;
    }
    .padding-top-2 {
        padding-top: 60px;
    }
    .padding-bottom-2 {
        padding-bottom: 60px;
    }
    .mw-lg-100 {
        max-width: 100%;
    }
}
.bg_theme {
    background: $theme-color;
}
.cate {
    font-family: $heading;
}
.section-header {
    text-align: center;
    .cate {
        color: $theme-color;
        font-size: 18px;
        display: block;
        margin-top: -10px;
        margin-bottom: 10px;
        text-transform: capitalize;
        font-weight: 400;
    }
    .title {
        text-transform: capitalize;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    p {
        margin-top: 0;
    }
    margin-bottom: 30px;
    @include breakpoint(sm) {
        margin-bottom: 35px;
        &.mb-olpo {
            margin-bottom: 25px;
        }
    }
    @include breakpoint(md) {
        .cate {
            font-size: 24px;
            margin-top: -9px;
            margin-bottom: 14px;
        }
    }
    @include breakpoint(lg) {
        margin-bottom: 50px;
        .cate {
            margin-bottom: 17px;
        }
        &.mb-olpo {
            margin-bottom: 30px;
            .cate {
                margin-bottom: 10px;
            }
        }
    }
    p {
        max-width: 570px;
        margin: 0 auto 0;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    &.left-style {
        text-align: left;
        p {
            margin-left: 0;
        }
    }
}
.tab-area {
    .tab-item {
        display: none;
        &.active {
            display: block;
        }
    }
}
.cl-white {
    * {
        color: $white-color;
    }
    .cate {
        color: $white-color;
    }
}
.cl-black {
    * {
        color: $body-color;
    }
}
.rtl {
    direction: rtl;
}
.ltr {
    direction: ltr;
}
.pos-rel {
    position: relative;
    .container  {
        position: relative;
        z-index: 1;
    }
}
.mb-40-none {
    margin-bottom: -40px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
    display: none;
    &.active {
        display: block;
        animation-name: fadeIn;
        -webkit-animation-name: fadeIn;
        -moz-animation-name: fadeIn;
        animation-duration: .3s;
        -webkit-animation-duration: .3s;
        -moz-animation-duration: .3s;
    }
}
.overlayTwo {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
    display: none;
    &.active {
        display: block;
        animation-name: fadeIn;
        -webkit-animation-name: fadeIn;
        -moz-animation-name: fadeIn;
        animation-duration: .3s;
        -webkit-animation-duration: .3s;
        -moz-animation-duration: .3s;
    }
}
.theme-overlay {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color:$theme-color, $alpha:.8);
    }
    >* {
        position: relative;
        z-index: 1;
    }
}
.ratings {
    i {
        color: #e8505b;
    }
}
.oh {
    overflow: hidden;
}
.mb--20--50 {
    margin-bottom: -20px;
    @media screen and (min-width:400px) {
        margin-bottom: -50px;
    }
}
.default-pagination {
    @extend %flex;
    margin: -7px;
    margin-top: 31px;
    justify-content: center;
    @include breakpoint(md) {
        margin-top: 41px;
    }
    li {
        padding: 7px;
        a {
            width: 46px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            @include border-radius(50%);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);              
            background: $white-color;
            color: $title-color;
            &:hover , &.active {
                background: $theme-color;
                box-shadow: none;
                color: $white-color;
            }
        }
    }
}