/*Gallery Section Starts Here*/
.top-shape-center {
    position: absolute;
    left: 50%;
    top: 0;
    @include transform(translateX(-50%));
    z-index: -1;

    img {
        max-width: 100%;
    }

    @include breakpoint(max-md) {
        display: none;
    }
}

.bottom-shape-left {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;

    img {
        max-width: 100%;
    }

    @include breakpoint(max-md) {
        display: none;
    }
}

.gallery-item {
    text-align: center;
    position: relative;
    margin: 0 20px 40px;

    @media screen and(min-width:400px) {
        margin-bottom: 60px;
    }

    .gallery-inner {
        padding: 15px;
        padding-bottom: 0;
        background: $white-color;
        position: relative;
        z-index: 1;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }

    &::before {
        @extend %pa;
        width: 100%;
        height: 100%;
        background: $white-color;
        left: 0;
        top: 0;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        @include transform(rotate(-4.5deg));
        @include transition($transition);
    }

    .gallery-thumb {
        a {
            display: block;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }

    .gallery-content {
        padding: 20px 0;

        .title {
            margin: 0;
            font-weight: 500;
            font-size: 18px;
            color: $theme-two;

            @include breakpoint(sm) {
                font-size: 20px;
            }
        }
    }

    &:hover {
        &::before {
            @include transform(rotate(0));
        }
    }
}

div[class*="col"] {
    &:nth-of-type(6n + 2) {
        .gallery-item {
            .gallery-content {
                .title {
                    color: $theme-color;
                }
            }
        }
    }

    &:nth-of-type(6n + 3) {
        .gallery-item {
            .gallery-content {
                .title {
                    color: $theme-three;
                }
            }
        }
    }

    &:nth-of-type(6n + 4) {
        .gallery-item {
            .gallery-content {
                .title {
                    color: $theme-four;
                }
            }
        }
    }

    &:nth-of-type(6n + 5) {
        .gallery-item {
            .gallery-content {
                .title {
                    color: $theme-five;
                }
            }
        }
    }

    &:nth-of-type(6n + 6) {
        .gallery-item {
            .gallery-content {
                .title {
                    color: $theme-six;
                }
            }
        }
    }
}

.filter-2 {
    @extend %flex;
    justify-content: center;
    margin: -5px;
    margin-bottom: 30px;

    li {
        background: $white-color;
        border-radius: 4px;
        box-shadow: 0px 2px 4px 0px rgba(79, 63, 82, 0.06);
        padding: 5px 20px;
        font-size: 14px;
        font-weight: 700;
        color: $title-color;
        text-transform: uppercase;
        margin: 5px;
        cursor: pointer;
        display: flex;

        .category {
            line-height: 38px;
        }

        .amount {
            height: 38px;
            line-height: 38px;
            text-align: center;
            width: 38px;
            text-align: center;
            margin-left: 5px;
            @include border-radius(50%);
            border: 1px solid #f3f7f8;
            display: block;
        }

        &.active {
            .amount {
                border-color: $theme-color;
                background: $theme-color;
                color: $white-color;
            }

            .category {
                color: $theme-color;
            }
        }
    }

    @include breakpoint(md) {
        margin-bottom: 35px;
    }
}

/*Gallery Masonary 1 Starts Here*/
.masonary-wrapper {
    margin: -15px;
}

.masonary-item {
    width: 100%;
    padding: 15px;

    .masonary-thumb {
        position: relative;
        @include border-radius(10px);
        box-shadow: 0px 2px 4px 0px rgba(79, 63, 82, 0.06);
        background: $white-color;
        padding: 8px;

        &::before {
            position: absolute;
            content: "";
            left: 8px;
            right: 8px;
            bottom: 8px;
            top: 8px;
            @include border-radius(10px);
            background: rgba($color: $theme-color, $alpha:.8);
            @include transition($transition);
            opacity: 0;
        }

        a {
            @extend %center;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: $theme-color;
            background: $white-color;
            @include border-radius(50%);
            opacity: 0;
        }

        img {
            width: 100%;
            @include border-radius(10px);
        }

        &:hover {
            &::before {
                opacity: 1;
            }

            a {
                opacity: 1;
            }
        }
    }

    @include breakpoint(sm) {
        width: 50%;
    }
}

.three-items {
    .masonary-item {
        @include breakpoint(md) {
            width: calc(100% / 3);
        }
    }
}

.lg-three-items {
    .masonary-item {
        width: 100%;

        @include breakpoint(md) {
            width: 50%;
        }

        @include breakpoint(lg) {
            width: calc(100% / 3);
        }
    }
}

/*Gallery Section Three Starts Here*/
.gallery-item-3 {
    text-align: center;
    border-width: 8px;
    box-shadow: 0px 2px 4px 0px rgba(79, 63, 82, 0.06);
    max-width: 400px;
    margin: 0 auto;

    .gallery-thumb {
        overflow: hidden;
        @include border-radius(8px 8px 0 0);

        img {
            width: 100%;
        }

        a {
            display: block;
        }
    }

    .gallery-content {
        background: $white-color;
        @include border-radius(0 0 8px 8px);
        padding: 35px 15px 28px;

        .title {
            font-size: 20px;
            margin-bottom: 5px;

            a {
                display: block;
            }
        }
    }
}

/*Facilities Section Starts Here*/
.facility-container {
    padding-left: 15px;
    padding-right: 15px;

    @include breakpoint(lg) {
        padding-left: 0;
        padding-right: 0;
        margin-right: 30px;
        margin-left: 30px;
    }

    @include breakpoint(xl) {
        max-width: 575px;
        margin-right: 40px;
        margin-left: auto;
        padding-left: 30px;
    }

    @include breakpoint(max-lg) {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint(max-md) {
        max-width: 540px;
    }

    @include breakpoint(max-sm) {
        max-width: 100%;
    }
}

.facility-wrapper {
    @extend %flex;
    margin: -7.5px;
}

.facility-item {
    width: 100%;
    padding: 7.5px;

    @media screen and (min-width:400px) {
        width: calc(100% / 2);
    }

    @include breakpoint(sm) {
        width: calc(100% / 3);
    }

    .facility-inner {
        text-align: center;
        padding: 30px 10px 25px;
        background: $white-color;
        border-radius: 6px;
        box-shadow: 0px 0px 8px 0px rgba(136, 136, 136, 0.08);
        position: relative;

        &::after {
            position: absolute;
            content: "";
            left: 5px;
            top: 5px;
            width: 50px;
            height: 50px;
            background: url(./img/facility-shape.png) no-repeat center center;
            background-size: contain;
            @include transition($transition);
        }

        .facility-thumb {
            max-width: 80px;
            margin: 0 auto 28px;

            img {
                width: 100%;
            }
        }
    }
}


/*Client Section Starts Here*/
.client-item {
    background: $white-color;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.1);

    .client-header {
        @extend %flex;
        align-items: center;
        padding: 20px 30px;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;

        .author {
            width: calc(100% - 60px);
            padding-right: 10px;
            @extend %flex;
            align-items: center;

            .thumb {
                width: 60px;

                img {
                    width: 100%;
                }
            }

            .content {
                width: calc(100% - 60px);
                padding-left: 13px;

                .title {
                    margin: 0;
                    text-transform: capitalize;
                    margin-bottom: -2px;

                    @include breakpoint(md) {
                        font-size: 18px;
                    }
                }

                span {
                    font-size: 15px;
                    text-transform: capitalize;
                }
            }
        }

        .company {
            width: 55px;

            img {
                max-width: 100%;
            }

            @include breakpoint(max-sm) {
                width: 40px;
            }
        }

        @include breakpoint(max-sm) {
            padding: 20px 15px;
        }
    }

    .client-body {
        padding: 30px 30px;

        @include breakpoint(max-sm) {
            padding: 26px 15px 25px;
        }
    }
}

.client-slider {
    margin: -15px;

    .slick-slide {
        margin: 15px;
        outline: none;
    }
}

/*Counter Section Starts Here*/
.counter-item {
    background: $white-color;
    text-align: center;
    padding: 35px 15px;
    border: 6px solid $theme-color;
    border-radius: 25px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;

    .counter-thumb {
        height: 130px;
        margin-bottom: 32px;

        img {
            max-height: 100%;
        }
    }

    .counter-header {
        @extend %flex;
        align-items: center;
        justify-content: center;

        .title {
            margin: 0;
            margin-bottom: 0;
            line-height: 1;
            color: $theme-color;

            @include breakpoint(lg) {
                font-size: 46px;
            }
        }
    }

    &.round-style {
        @include border-radius(50%);
        border: none;
        padding: 40px 15px 45px;
        @extend %flex;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: 270px;
        margin: 0 auto 30px;

        &::before {
            @extend %pa;
            left: 5px;
            right: 5px;
            top: 5px;
            bottom: 5px;
            border: 2px dashed $theme-two;
            @include border-radius(50%);
            @include transition($transition);
        }

        .counter-thumb {
            height: unset;
            margin-bottom: 26px;
            height: 86px;
            @extend %flex;
            align-items: center;
            justify-content: center;

            img {
                margin-top: auto;
            }
        }

        .counter-content {
            width: 100%;

            .title {
                margin-bottom: 5px;
                color: $theme-two;

                @include breakpoint(md) {
                    font-size: 36px;
                }
            }
        }

        @include breakpoint(sm) {
            @include breakpoint(max-md) {
                padding-top: 30px;
                padding-bottom: 35px;

                .counter-thumb {
                    margin-bottom: 22px;
                }

                .counter-content {
                    .title {
                        font-size: 30px;
                    }
                }
            }
        }

        &:hover {
            &::before {
                @include transform(rotate(3deg));
            }
        }
    }
}

div[class*="col"] {
    &:nth-of-type(6n + 2) {
        .counter-item {
            border-color: $theme-five;

            .counter-header {
                .title {
                    color: $theme-five;
                }
            }

            &.round-style {
                .counter-header {
                    .title {
                        color: $theme-color;
                    }
                }

                &::before {
                    border-color: $theme-color;
                }
            }
        }
    }

    &:nth-of-type(6n + 3) {
        .counter-item {
            border-color: $theme-one;

            .counter-header {
                .title {
                    color: $theme-one;
                }
            }

            &.round-style {
                &::before {
                    border-color: $theme-one;
                }
            }
        }
    }

    &:nth-of-type(6n + 4) {
        .counter-item {
            border-color: $theme-four;

            .title {
                color: $theme-four;
            }

            &.round-style {
                &::before {
                    border-color: $theme-four;
                }
            }
        }
    }
}

.counter-section {
    @include breakpoint(max-md) {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            background: rgba($color:$theme-two, $alpha:1);
        }
    }
}

/*Class Section Starts Here*/
.class-item {
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 15px;
    margin-bottom: 30px;

    .class-thumb {
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        margin-bottom: 30px;

        a {
            display: block;

            img {
                @include transition($transition);
                width: 100%;
            }
        }
    }

    .class-content {
        padding-left: 15px;
        padding-right: 15px;

        .title {
            margin-bottom: 15px;

            @include breakpoint(md) {
                @include breakpoint(max-lg) {
                    font-size: 22px;
                }
            }
        }

        .class-meta {
            @extend %flex;
            margin: 5px 0;
            justify-content: space-between;

            .class-author {
                @extend %flex;
                align-items: center;
                margin-bottom: 15px;

                .thumb {
                    width: 40px;
                    height: 40px;
                    @include border-radius(50%);
                    overflow: hidden;

                    a {
                        display: block;
                    }

                    img {
                        width: 100%;
                    }
                }

                .content {
                    width: calc(100% - 40px);
                    padding-left: 12px;

                    .subtitle {
                        font-size: 16px;
                        margin: 0;
                        line-height: 1.1;

                        a {

                            // color: $body-color;
                            &:hover {
                                color: $theme-color;
                            }
                        }
                    }

                    .info {
                        color: $body-color;
                        font-size: 13px;
                    }
                }
            }

            .cost-area {
                margin-bottom: 15px;

                .subtitle {
                    color: $theme-three;
                    margin: 0;
                    margin-bottom: -3px;
                }

                .info {
                    font-size: 13px;
                }
            }
        }

        .schedule-area {
            @extend %flex;
            text-align: center;

            .schedule-item {
                padding: 25px 5px 0px;
                flex-grow: 1;
                position: relative;

                &::before {
                    @extend %pa;
                    top: 0;
                    left: 1px;
                    right: 1px;
                    height: 3px;
                    background: $theme-two;
                }

                .sub-title {
                    margin: 0;
                    margin-bottom: -2px;
                    color: $theme-two;
                    font-weight: 500;

                    @include breakpoint(sm) {
                        font-size: 18px;
                    }
                }

                .info {
                    font-size: 14px;
                }

                &:nth-of-type(3n + 2) {
                    &::before {
                        background: $theme-color;
                    }

                    .sub-title {
                        color: $theme-color;
                    }
                }

                &:nth-of-type(3n + 3) {
                    &::before {
                        background: $theme-three;
                    }

                    .sub-title {
                        color: $theme-three;
                    }
                }
            }
        }
    }

    &:hover {
        .class-thumb {
            img {
                @include transform(scale(1.1));
            }
        }
    }

    @media screen and (max-width:374px) {
        padding: 10px;

        .class-thumb {
            margin-bottom: 25px;
        }

        .class-content {
            padding-left: 0;
            padding-right: 0;

            .class-meta {
                .class-author {
                    .content {
                        .subtitle {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.class-wrapper {
    margin: 0 -15px -30px;
}

.class-inner {
    width: 100%;
    padding: 0 15px;

    @include breakpoint(md) {
        width: calc(100% / 2);
    }

    @include breakpoint(xl) {
        width: calc(100% / 3);
    }
}

.class-single-item {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

    .class-single-inner {
        overflow: hidden;
        border-radius: 10px;
        background: $white-color;

        .class-single-thumb {
            img {
                width: 100%;
            }
        }

        .class-single-content {
            padding: 30px;

            .title {
                margin-bottom: 9px;
            }

            .meta-post {
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 31px;
            }

            @include breakpoint(max-sm) {
                padding: 35px 15px 30px;
            }

            .single-quote {
                margin-bottom: 27px;
            }

            .thumb-area,
            .single-page-maps,
            .video-area {
                margin-bottom: 27px;
                @include border-radius(10px);
                overflow: hidden;

                img {
                    width: 100%;
                }
            }

            @include breakpoint(max-sm) {
                .title {
                    margin-bottom: 12px;
                }

                .meta-post {
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 25px;
                }

                .single-quote {
                    margin-bottom: 21px;
                }

                .video-area {
                    margin-bottom: 21px;
                }
            }
        }
    }
}

.meta-post {
    @extend %flex;
    margin-right: -15px;

    @include breakpoint(max-sm) {
        font-size: 14px;
    }

    li {
        padding: 0;
        margin-right: 15px;

        a {
            color: $body-color;

            &:hover {
                color: $theme-color;
            }

            i {
                margin-right: 5px;
            }
        }
    }
}

.single-quote {
    background: $theme-color;
    color: $white-color;
    border-radius: 10px;
    padding: 25px 15px 20px;
    position: relative;
    @extend %flex;

    &::before {
        content: "";
        width: 50px;
        height: 50px;
        background: url(./img/quote.png) no-repeat center center;
        background-size: contain;
        margin-bottom: 15px;
    }

    .quotes {
        width: 100%;

        @include breakpoint(md) {
            font-size: 18px;
            line-height: 30px;
        }
    }

    @media screen and (min-width:550px) {
        padding: 35px 30px 30px;

        .quotes {
            width: calc(100% - 50px);
            padding-left: 15px;
        }
    }

    span {
        margin-left: auto;
        display: block;
        text-align: right;
    }
}

.video-area {
    position: relative;

    &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @extend %pa;
        background: rgba($color:$theme-color, $alpha:.4);
    }

    .video-button {
        @extend %center;
    }

    img {
        width: 100%;
    }
}

.tags-area {
    @extend %flex;
    justify-content: space-between;
    border-top: 1px solid #f6f9fb;
    padding: 25px 10px;

    @include breakpoint(sm) {
        padding: 25px;
    }
}

.tags {
    @extend %flex;

    li {
        padding: 5px;

        a {
            border: 1px solid #f6f9fb;
            color: $title-color;
            line-height: 40px;
            padding: 0 15px;
            @include border-radius(3px);

            &:hover,
            &.active {
                background: $theme-color;
                color: $white-color;
                border-color: $theme-color;
            }
        }
    }
}

.share {
    @extend %flex;

    li {
        padding: 5px;

        a {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: $theme-color;
            color: $white-color;
            @include border-radius(1px);

            &.facebook {
                background: $facebook;
            }

            &.twitter {
                background: $twitter;
            }

            &.instagram {
                background: $instagram;
            }

            &.google {
                background: $google;
            }

            &.linkedin {
                background: $linkedin;
            }

            &.vimeo {
                background: $vimeo;
            }

            &.pinterest {
                background: $pinterest;
            }

            &.behance {
                background: $behance;
            }

            &.dribble {
                background: $dribble;
            }
        }
    }
}

.filter {
    border-radius: 4px;
    background-color: $white-color;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
    @extend %flex;
    justify-content: center;
    margin-bottom: 30px;
    padding: 15px 30px;
    text-align: center;

    @include breakpoint(xl) {
        padding-left: 50px;
        padding-right: 50px;
    }

    li {
        list-style: none;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: $theme-two;
        @include transition($transition);
        padding: 6px 15px;
        flex-grow: 1;
        cursor: pointer;

        &:nth-of-type(6n + 2) {
            color: $theme-color;
        }

        &:nth-of-type(6n + 3) {
            color: $theme-three;
        }

        &:nth-of-type(6n + 4) {
            color: $theme-four;
        }

        &:nth-of-type(6n + 5) {
            color: $theme-five;
        }

        &:nth-of-type(6n + 6) {
            color: $theme-six;
        }

        &.active {
            color: $theme-two;
        }
    }

    @include breakpoint(max-sm) {
        li {
            padding: 3px 10px;
        }
    }
}

/*About Section Starts Here*/
.about-video {
    position: relative;
    @include border-radius(10px);
    overflow: hidden;
    z-index: 1;

    img {
        width: 100%;
    }

    &::before {
        @extend %pa;
        left: 8px;
        bottom: 8px;
        right: 8px;
        top: 8px;
        background: rgba($color:$theme-color, $alpha:.3);
    }

    &::after {
        @extend %pa;
        left: 0px;
        bottom: 0px;
        right: 0px;
        top: 0px;
        border: 8px solid $theme-two;
        @include border-radius(10px);
    }

    .video-button {
        @extend %center;
    }

    @include breakpoint(max-lg) {
        // max-width: 500px;
        margin: 0 auto 45px;

        @include breakpoint(max-sm) {
            margin-bottom: 30px;
        }
    }
}

.nulla-list {
    @extend %flex;
    margin: 0 -10px -18px;

    li {
        list-style: none !important;
        @extend %flex;
        width: 50%;
        max-width: 260px;
        padding: 0 10px;
        margin-bottom: 17px;

        @include breakpoint(max-lg) {
            width: 100%;
        }

        .thumb {
            width: 30px;

            img {
                max-width: 100%;
            }
        }

        .content {
            width: calc(100% - 30px);
            padding-left: 9px;
            font-weight: 700;
            color: $title-color;
        }
    }
}

.about-content {
    .nulla-list {
        margin-bottom: 20px;
    }
}

.about-contact {
    @extend %flex;
    position: relative;

    &::before {
        @extend %pa;
        bottom: 0;
        top: 0;
        right: 0px;
        left: -85px;
        border-bottom: 1px solid $border-color;
        border-top: 1px solid $border-color;

        @include breakpoint(max-lg) {
            left: 0;
            right: 0;
        }
    }

    .call-item {
        @extend %flex;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        max-width: 310px;
        border-right: 1px solid $border-color;
        position: relative;
        z-index: 1;

        .thumb {
            width: 50px;

            img {
                width: 100%;
            }
        }

        .content {
            width: calc(100% - 50px);
            padding-left: 15px;

            .info {
                display: block;
                margin-bottom: 5px;
            }
        }
    }

    .sign {
        width: calc(100% - 310px);
        padding: 20px;
        text-align: center;

        img {
            max-width: 100%;
        }
    }

    @media screen and (max-width:499px) {
        .sign {
            width: 100%;
            padding: 10px;
            text-align: left;
        }

        .call-item {
            width: 100%;
            max-width: 100%;
            border: none;
            border-bottom: 1px solid $border-color;
        }
    }
}

.section-right-shape {
    position: relative;
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        z-index: -1;
        right: calc(50% - 585px);
        left: calc(50% - 70px);
        background: $white-color;

        @include breakpoint(max-xl) {
            right: calc(50% - 496px);
        }

        @include breakpoint(max-lg) {
            display: none;
        }
    }
}

/*Teacher Section Starts Here*/
.teacher-item {
    box-shadow: 0px 0px 10px 0px rgba(137, 137, 137, 0.1);
    background: $white-color;
    border-radius: 10px;
    margin: 0 auto 30px;

    .teacher-inner {
        overflow: hidden;
        border-radius: 10px;
        @extend %flex;

        .teacher-thumb {
            padding: 30px 34px;
            position: relative;
            width: calc(100% - 310px);

            &::before {
                background: $theme-two;
                @extend %center;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 0% 100%, 100% 100%);
                -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
            }

            .thumb-inner {
                a {
                    display: block;
                    position: relative;
                    z-index: 1;

                    &::before {
                        @include border-radius(10px);
                        @extend %center;
                        width: 100%;
                        height: 100%;
                        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                        border: 6px solid $white-color;
                    }
                }

                img {
                    width: 100%;
                    @include border-radius(10px);
                }
            }
        }

        .teacher-content {
            width: 310px;
            padding: 30px 30px 30px 0;

            .title {
                margin: 0;
                font-size: 20px;
            }

            .info {
                color: $title-color;
                display: block;
                font-size: 14px;
                margin-bottom: 15px;
            }

            p {
                margin-bottom: 20px;
                line-height: 24px;
            }
        }

        @include breakpoint(lg) {
            @include breakpoint(max-xl) {

                .teacher-thumb,
                .teacher-content {
                    width: 100%;
                    padding: 30px;
                }
            }
        }

        @include breakpoint(max-md) {
            @media screen and (min-width:500px) {

                .teacher-thumb,
                .teacher-content {
                    width: 50%;
                }

                .teacher-thumb {
                    padding: 20px;
                }

                .teacher-content {
                    padding: 20px;
                    padding-left: 0;
                }
            }
        }
    }

    @media screen and (max-width:499px) {
        .teacher-inner {

            .teacher-thumb,
            .teacher-content {
                width: 100%;
                padding: 30px;
            }
        }
    }
}

.teacher-social {
    @extend %flex;
    margin: -5px;

    li {
        padding: 5px;
        list-style: none !important;

        a {
            color: $theme-color;

            &.facebook {
                color: $facebook;
            }

            &.behance {
                color: $behance;
            }

            &.twitter {
                color: $twitter;
            }

            &.google {
                color: $google;
            }

            &.instagram {
                color: $instagram;
            }

            &.linkedin {
                color: $linkedin;
            }

            &.vimeo {
                color: $vimeo;
            }

            &.pinterest {
                color: $pinterest;
            }

            &.youtube {
                color: $youtube;
            }
        }
    }
}

div[class*="col"] {
    &:nth-of-type(4n + 2) {
        .teacher-item {
            .teacher-thumb {
                &::before {
                    background: $theme-color;
                }
            }
        }
    }

    &:nth-of-type(4n + 3) {
        .teacher-item {
            .teacher-thumb {
                &::before {
                    background: $theme-three;
                }
            }
        }
    }

    &:nth-of-type(4n + 4) {
        .teacher-item {
            .teacher-thumb {
                &::before {
                    background: $theme-four;
                }
            }
        }
    }
}

/*Schedule Section Starts Here*/
.schedule-item-2 {
    padding: 170px 0 75px;
    text-align: center;
    margin-bottom: 50px;

    li {
        margin-bottom: 6px;
        list-style: none !important;

        .title {
            font-size: 18px;
            text-transform: capitalize;
            color: $theme-color;
            font-weight: 500;

            @include breakpoint(sm) {
                margin-bottom: 4px;
            }

            &.painting {
                color: $theme-two;
            }

            &.fitness {
                color: $theme-three;
            }
        }
    }

    @media screen and (max-width: 399px) {
        background-size: 100% 100%;
        margin-bottom: 20px;
    }
}


/*Cart Area Sidebar Starts*/
.cart-sidebar-area {
    background-color: $title-color;
    max-width: 350px;
    width: 100%;
    height: 100vh;
    padding: 30px;
    position: fixed;
    right: 0;
    overflow-y: scroll;
    top: 0;
    z-index: 9999;
    -webkit-transition: 0.7s ease;
    -moz-transition: 0.7s ease;
    -o-transition: 0.7s ease;
    transition: 0.7s ease;
    -ms-transform: translateX(350px);
    /* IE 9 */
    -webkit-transform: translateX(350px);
    /* Chrome, Safari, Opera */
    transform: translateX(350px);
}

.cart-sidebar-area.active {
    visibility: visible;
    opacity: 1;
    -ms-transform: translateX(0px);
    /* IE 9 */
    -webkit-transform: translateX(0px);
    /* Chrome, Safari, Opera */
    transform: translateX(0px);
}

.cart-sidebar-area .btn-wrapper {
    margin-top: 40px;
}

.cart-sidebar-area .top-content {
    position: relative;
    margin-bottom: 35px;
}

.side-sidebar-close-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    display: inline-block;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}

.cart-products .title {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    margin: 0;
    margin-bottom: 25px;
}

.single-product-item {
    min-height: 80px;
    margin-bottom: 20px;
    @extend %flex;
}

.single-product-item .thumb {
    width: 100px;
    padding: 5px;
    @extend %flex;
    justify-content: center;
    align-items: center;
    background: $white-color;
    height: 100px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.single-product-item .content {
    width: calc(100% - 100px);
    padding-left: 20px;

    .price {
        color: rgba($color:$white-color, $alpha:.4);

        .pprice {
            color: $white-color;
        }
    }
}

.single-product-item .content .title {
    font-size: 16px;
    line-height: 26px;
    color: #fff;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0px;
}

.single-product-item .content .remove-cart {
    display: inline-block;
    font-size: 10px;
    color: #fff;
    background-color: $theme-four;
    padding: 2px 18px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-top: 10px;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.single-product-item .content .remove-cart:hover {
    opacity: .8;
}

.overlay {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;

    &.active {
        visibility: visible;
        opacity: 1;
    }
}

/*Teacher Section Two Starts Here*/
.teacher-item-2 {
    @include border-radius(10px);
    background-color: $white-color;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;

    .teacher-thumb {
        padding: 35px 15px 25px;
        border-bottom: 1px solid #f0f0f0;

        .thumb {
            box-shadow: 0px 2px 4px 3px rgba(83, 61, 82, 0.06);
            @include border-radius(50%);
            border: 8px solid $white-color;
            max-width: 200px;
            margin: 0 auto 35px;

            a {
                display: block;
                overflow: hidden;
                @include border-radius(50%);
            }

            img {
                width: 100%;
                @include border-radius(50%);
            }
        }

        .content {
            text-align: center;

            .title {
                font-size: 20px;
                text-transform: capitalize;
            }
        }
    }

    .teacher-social {
        margin: 0;

        li {
            a {
                padding: 5px;
            }
        }
    }
}

/*Call In Action Section Stars Here*/
.call-wrapper {
    @include border-radius(10px);
    background: $white-color;
    position: relative;
    padding: 60px 30px;
    max-width: 840px;
    margin: 0 auto;

    @include breakpoint(max-sm) {
        padding: 45px 30px;
    }

    .section-header {
        max-width: 710px;
        margin-left: auto;
        margin-right: auto;

        .title {
            font-family: $heading;
            color: $theme-color;
            margin-bottom: 5px;
        }

        p {
            // max-width: 100%;
        }
    }

    * {
        position: relative;
        z-index: 1;
    }

    &::before {
        @extend %pa;
        border: 1px dashed $theme-color;
        left: 10px;
        right: 10px;
        bottom: 10px;
        top: 10px;
        @include border-radius(10px);
    }
}

.call-overlay {
    position: relative;

    &::before {
        @extend %pa;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgb(51, 205, 252);
        opacity: .78;
    }
}

.theme-overlay {
    position: relative;

    &::before {
        @extend %pa;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background-color: $theme-color;
        opacity: .9;
    }
}

/*Feature Section Starts Here*/
.feature-item {
    background: $white-color;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    padding: 40px 30px 50px;
    text-align: center;
    @include transition($transition);
    margin-bottom: 30px;

    &:hover {
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    }

    @include breakpoint(xl) {
        margin-left: -5px;
        margin-right: -5px;
    }

    .feature-thumb {
        max-width: 110px;
        margin: 0 auto 24px;

        img {
            width: 100%;
        }
    }

    .feature-content {
        .title {
            margin-bottom: 3px;
        }

        .cate {
            display: block;
            margin-bottom: 16px;
            color: $theme-two;
        }

        p {
            line-height: 24px;
        }
    }

    @include breakpoint(max-md) {
        padding-top: 30px;
        padding-bottom: 35px;
        // max-width: 320px;
        margin-left: auto;
        margin-right: auto;
    }
}

.ml--8 {
    margin-left: -8px;
}

div[class*="col"] {
    &:nth-of-type(4n + 2) {
        .feature-item {
            .feature-content {
                .cate {
                    color: $theme-color;
                }
            }
        }
    }

    &:nth-of-type(4n + 3) {
        .feature-item {
            .feature-content {
                .cate {
                    color: $theme-three;
                }
            }
        }
    }

    &:nth-of-type(4n + 4) {
        .feature-item {
            .feature-content {
                .cate {
                    color: $theme-four;
                }
            }
        }
    }
}

/*About Section Starts Here*/
.about-thumb {
    margin-bottom: 20px;

    @include breakpoint(sm) {
        margin-bottom: 30px;
    }

    img {
        width: 100%;
    }

    @include breakpoint(lg) {
        margin-bottom: 0;
    }
}

.about-list {
    li {
        font-size: 18px;
        color: $title-color;
        padding: 0;

        span {
            color: $theme-two;
            font-weight: 700;
        }

        i {
            font-weight: 700;
            margin-right: 5px;
            font-size: 20px;
        }

        &:nth-of-type(4n + 2) {

            span,
            i {
                color: $theme-color;
            }
        }

        &:nth-of-type(4n + 3) {

            span,
            i {
                color: $theme-three;
            }
        }

        &:nth-of-type(4n + 4) {

            span,
            i {
                color: $theme-four;
            }
        }

        margin-bottom: 9px;

        &:last-child {
            margin-bottom: -8px;
        }
    }

    @include breakpoint(max-sm) {
        li {
            font-size: 16px;
        }
    }
}

.about-content {
    .about-list {
        margin-bottom: 40px;
    }

    .section-header {
        @include breakpoint(lg) {
            margin-bottom: 25px;
        }

        @include breakpoint(xl) {
            padding-right: 15px;
        }
    }
}

.about-quote {
    padding: 15px 0;
    border-bottom: 1px solid rgb(255, 215, 161);
    border-top: 1px solid rgb(255, 215, 161);
    @extend %flex;

    .thumb {
        width: 70px;
        height: 70px;
        @include border-radius(50%);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
        background: $white-color;

        img {
            width: 100%;
            padding: 5px;
        }
    }

    .content {
        width: calc(100% - 70px);
        padding-left: 15px;
        font-size: 24px;
        font-weight: 6500;
        color: $theme-three;

        .name {
            color: $title-color;
            font-size: 18px;
            font-family: $heading;

            &::after {
                content: " )";
            }

            &::before {
                content: "( ";
            }
        }
    }

    @include breakpoint(max-sm) {
        .thumb {
            margin-bottom: 15px;
        }

        .content {
            width: 100%;
            padding: 0;
            font-size: 18px;

            .name {
                font-size: 16px;
            }
        }
    }
}

/*Contact Section Starts Here*/
.maps {
    height: 400px;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    @include breakpoint(lg) {
        height: 600px;
    }

    &.single-page-maps {
        height: 370px;
        @include border-radius(10px);
        .contact-area {
            display: none;
        }
    }
}

.contact-area {
    @include breakpoint(lg) {
        position: absolute;
        left: 0;
        bottom: 30px;
        right: 0;

        .contact-wrapper {
            border-radius: 10px;
            background-color: $white-color;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
            padding: 50px 15px 13px;

            @include breakpoint(max-xl) {
                padding-left: 0;
                margin-right: 0;
            }
        }
    }

    @include breakpoint(max-lg) {
        margin-top: -220px;
    }
}

.contact-wrapper {
    @extend %flex;
    text-align: center;

    .contact-item {
        padding: 0 15px 30px;
        width: calc(100% / 4);

        .contact-thumb {
            margin-bottom: 30px;

            img {
                max-width: 100%;
            }
        }

        .contact-content {
            .title {
                font-family: $heading;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                padding: 0;
                line-height: 24px;

                a {
                    color: $body-color;
                }

                @include breakpoint(lg) {
                    @include breakpoint(max-xl) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @include breakpoint(max-lg) {
        margin: 0 0 -30px;
        justify-content: space-between;

        .contact-item {
            padding: 30px 15px;
            border-radius: 10px;
            background-color: $white-color;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
            width: calc((100% / 2) - 15px);
            margin-bottom: 30px;

            .contact-content {
                li {
                    @include breakpoint(max-sm) {
                        font-size: 14px;
                    }
                }
            }

            @media screen and (max-width:499px) {
                width: 100%;
                // max-width: 320px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.contact-form-area {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    padding: 50px 30px;
    max-width: 790px;
    margin: 0 auto;

    .title {
        margin-bottom: 32px;
        text-align: center;
    }

    @include breakpoint(max-md) {
        padding: 45px 20px;
    }

    @include breakpoint(max-sm) {
        padding: 0;
        background: transparent;
        box-shadow: none;

        .title {
            margin-bottom: 22px;
        }
    }
}

.contact-form {
    @extend %flex;
    justify-content: space-between;
    margin-bottom: -30px;

    .form-group {
        margin-bottom: 30px;
        width: 100%;

        @include breakpoint(sm) {
            width: calc(50% - 15px);
        }

        input {
            border: 1px solid rgb(240, 240, 240);
            border-radius: 4px;
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
            height: 57px;
            padding: 0 25px;
        }

        textarea {
            border: 1px solid rgb(240, 240, 240);
            border-radius: 4px;
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
            padding: 25px;
            color: $body-color;
            display: flex;
        }

        button {
            height: 55px;
        }
    }

    @include breakpoint(max-sm) {
        margin-bottom: -20px;

        .form-group {
            margin-bottom: 20px;
        }
    }
}

.email-success {
    width: 100%;
}

/*Faq Section Starts Here*/
.right-icon {
    width: 36px;
    height: 36px;
    @include border-radius(50%);
    background: $theme-two;
    position: relative;
    @include transform(translateX(-15px));
    &::after,
    &::before {
        @extend %pa;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        background: $white-color;
        @include transition($transition);
    }

    &::after {
        height: 12px;
        width: 2px;
        display: none;
    }

    &::before {
        width: 12px;
        height: 2px;
    }
}
.collapsed {
    .right-icon {
        &::after {
            display: block;
        }
    }
}

.faq-item {
    background: $white-color;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    padding: 20px 30px;
    margin-bottom: 10px;
    border: none !important;

    .faq-title {
        @extend %flex;
        cursor: pointer;
        align-items: center;

        .title {
            width: calc(100% - 36px);
            padding-left: 15px;
            font-size: 18px;
            line-height: 1.4;
            margin: 0;
            outline: none;
            box-shadow: none;
            border: none;
            color: inherit;
            font-weight: 700;
            color: $theme-two;
            background-color: inherit;
            @include transition($transition);
            &::after {
                display: none;
            }
        }
    }

    .faq-content {
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        // display: none;
    }

    &.collapsed {
        .title {
            color: $theme-two;
        }

        .right-icon {
            &::after {
                height: 2px;
            }
        }
    }

    &.active {
        .faq-content {
            display: block;
        }
    }

    &:nth-child(4n + 2) {
        .right-icon {
            background: $theme-color;
        }

        .title {
            color: $theme-color;
        }
        
    }

    &:nth-child(4n + 3) {
        .right-icon {
            background: $theme-three;
        }

        .title {
            color: $theme-three;
        }
    }

    &:nth-child(4n + 4) {
        .right-icon {
            background: $theme-four;
        }

        .title {
            color: $theme-four;
        }
    }

    @media screen and (max-width:499px) {
        padding-left: 15px;
        padding-right: 15px;

        .faq-content {
            padding-top: 10px;
        }

        .faq-title {
            .title {
                font-size: 16px;
                width: calc(100% - 26px);
            }

            .right-icon {
                width: 26px;
                height: 26px;
            }
        }
    }
}

/*Page Header Starts Here*/
.page-header {
    padding: 180px 0;

    @include breakpoint(max-xl) {
        padding: 140px 0;
    }

    @include breakpoint(max-md) {
        position: relative;

        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: rgba($color: $theme-color, $alpha:.9);
        }

        .page-header-content {
            position: relative;
            z-index: 1;

            .title {
                color: $white-color;
            }

            .breadcrumb {

                li,
                li a {
                    color: $white-color;
                }
            }
        }
    }
}

.page-header-content {
    text-align: center;

    .title {
        font-size: 36px;
        line-height: 1.3;
        text-transform: capitalize;
        margin-bottom: 5px;
    }

    .breadcrumb {
        justify-content: center;
        margin: 0;
        padding: 0;
        background: transparent;

        li {
            color: $title-color;

            a {
                color: $title-color;

                &::after {
                    content: "-";
                    margin-left: 5px;
                    margin-right: 5px;
                    color: $white-color;

                    @include breakpoint(sm) {
                        color: $title-color;
                    }
                }

                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
}


/*Events Section Starts Here*/
.event-thumb {
    position: relative;
    overflow: hidden;
    @include border-radius(10px 10px 0 0);

    a {
        display: block;
    }

    img {
        width: 100%;
        @include transition($transition);
    }

    .date-tag {
        width: 80px;
        height: 80px;
        @extend %flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 10px;
        left: 10px;

        .title {
            margin: 0;
            line-height: 1;
            color: $white-color;
        }

        * {
            width: 100%;
            position: relative;
            z-index: 2;
        }

        color: rgba($color:$white-color, $alpha:.9);

        &::after {
            @extend %pa;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 4px;
            background: $theme-two;
            z-index: 1;
            @include transition($transition);
        }

        &::before {
            @extend %pa;
            left: 0;
            right: 0;
            top: 0;
            bottom: -4px;
            background: #69b600;
            border-radius: 4px;
            @include transition($transition);
        }
    }
}

.event-item {
    background: $white-color;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    margin: 0 auto 30px;

    .event-content {
        padding: 30px 15px 25px;

        @include breakpoint(sm) {
            padding: 30px 30px 25px;
        }

        .title {
            margin-bottom: 12px;
        }

        .events-info {
            li {
                list-style: none;
                padding: 3px 0;

                &:last-child {
                    padding-bottom: 0;
                }

                i {
                    margin-right: 5px;
                    color: $theme-two;
                }

                &:nth-of-type(2n + 2) {
                    i {
                        color: $theme-color;
                    }
                }
            }
        }
    }

    &:hover {
        .event-thumb {
            a {
                img {
                    @include transform(scale(1.1));
                }
            }

            .date-tag {
                &::before {
                    bottom: 0;
                }
            }
        }
    }
}

div[class*="col"] {
    &:nth-of-type(5n + 2) {
        .event-item {
            .event-thumb {
                .date-tag {
                    &::after {
                        background: $theme-color;
                    }

                    &::before {
                        background: #782075;
                    }
                }
            }
        }
    }
}

div[class*="col"] {
    &:nth-of-type(5n + 3) {
        .event-item {
            .event-thumb {
                .date-tag {
                    &::after {
                        background: $theme-three;
                    }

                    &::before {
                        background: #de4800;
                    }
                }
            }
        }
    }
}

div[class*="col"] {
    &:nth-of-type(5n + 4) {
        .event-item {
            .event-thumb {
                .date-tag {
                    &::after {
                        background: $theme-five;
                    }

                    &::before {
                        background: #b50005;
                    }
                }
            }
        }
    }
}

div[class*="col"] {
    &:nth-of-type(5n + 5) {
        .event-item {
            .event-thumb {
                .date-tag {
                    &::after {
                        background: $theme-four;
                    }

                    &::before {
                        background: #00a3db;
                    }
                }
            }
        }
    }
}

.class-inner {
    &:nth-of-type(5n + 2) {
        .event-item {
            .event-thumb {
                .date-tag {
                    &::after {
                        background: $theme-color;
                    }

                    &::before {
                        background: #782075;
                    }
                }
            }
        }
    }
}

.class-inner {
    &:nth-of-type(5n + 3) {
        .event-item {
            .event-thumb {
                .date-tag {
                    &::after {
                        background: $theme-three;
                    }

                    &::before {
                        background: #de4800;
                    }
                }
            }
        }
    }
}

.class-inner {
    &:nth-of-type(5n + 4) {
        .event-item {
            .event-thumb {
                .date-tag {
                    &::after {
                        background: $theme-five;
                    }

                    &::before {
                        background: #b50005;
                    }
                }
            }
        }
    }
}

.class-inner {
    &:nth-of-type(5n + 5) {
        .event-item {
            .event-thumb {
                .date-tag {
                    &::after {
                        background: $theme-four;
                    }

                    &::before {
                        background: #00a3db;
                    }
                }
            }
        }
    }
}

/*Single Countdown Starts Here*/
.countdown {
    margin: -12px 3px -12px 11px;
    text-align: center;

    li {
        width: 74px;
        height: 80px;
        margin: 12px;
        @extend %flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        &::after {
            @extend %pa;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: url(./img/shape1.png) no-repeat center center;
            background-size: contain;
            z-index: 1;
        }

        &::before {
            @extend %pa;
            top: 8px;
            left: -8px;
            bottom: -8px;
            right: 8px;
            background: url(./img/shadow.png) no-repeat center center;
            background-size: contain;
        }

        * {
            margin: 0;
            line-height: 1;
            text-transform: capitalize;
            color: $white-color;
            position: relative;
            z-index: 2;
        }

        .count-title {
            margin-bottom: 2px;
        }

        p {
            margin-bottom: 0 !important;
        }

        &:nth-of-type(4n + 2) {
            &::after {
                background: url(./img/shape2.png) no-repeat center center;
                background-size: contain;
            }
        }

        &:nth-of-type(4n + 3) {
            &::after {
                background: url(./img/shape3.png) no-repeat center center;
                background-size: contain;
            }
        }

        &:nth-of-type(4n + 4) {
            &::after {
                background: url(./img/shape4.png) no-repeat center center;
                background-size: contain;
            }
        }
    }

    @include breakpoint(max-sm) {
        margin: -8px 7px -8px 15px;

        li {
            margin: 8px;
        }
    }
}

.countdown-area {
    @extend %flex;
    align-items: center;
    justify-content: space-between;
    margin: -18px -15px 5px;

    .countdown {
        @extend %flex;
        margin-bottom: 10px;
        justify-content: center;
    }

    .counts-title {
        margin: 0 15px 10px;
    }
}

/*Account Section Starts Here*/
.account-wrapper {
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    background: $white-color;
    padding: 60px 80px;
    text-align: center;
    max-width: 545px;
    margin: 0 auto;

    .title {
        // text-transform: uppercase;
        text-transform: capitalize;
        margin-bottom: 20px;
    }

    @include breakpoint(max-md) {
        padding: 50px 40px;
    }

    @media screen and (max-width:499px) {
        padding: 40px 15px;
    }

    .cate {
        font-style: normal;
    }
}

.account-bottom {
    .subtitle {
        margin-bottom: 23px;

        @include breakpoint(sm) {
            font-size: 20px;
        }
    }
}

.account-form {
    .form-group {
        margin-bottom: 15px;

        input {
            border-style: solid;
            border-width: 1px;
            border-color: rgb(240, 240, 240);
            @include border-radius(4px);
            background: $white-color;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
            padding: 0 15px;
        }

        .checkgroup {
            @extend %flex;
            flex-wrap: nowrap;
            align-items: center;

            input {
                &[type="checkbox"] {
                    width: 18px;
                    height: 18px;
                    margin-right: 15px;

                    @include breakpoint(max-sm) {
                        width: 14px;
                        height: 14px;
                        margin-right: 8px;
                    }
                }
            }

            label {
                margin: 0;
                color: $body-color;

                @include breakpoint(max-sm) {
                    font-size: 14px;
                }
            }
        }

        a {
            color: $body-color;
            text-decoration: underline;

            @include breakpoint(max-sm) {
                font-size: 14px;
            }

            &:hover {
                color: $theme-color
            }
        }

        button {
            margin-top: 34px;

            @include breakpoint(max-sm) {
                margin-top: 24px;
            }
        }

        .custom-button {
            outline: none;
            width: 100%;
            padding-bottom: 10px;
            height: 50px;

            span {
                line-height: 50px;
            }
        }
    }
}

.or {
    display: block;
    margin: 24px auto 30px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    @include border-radius(50%);
    background: $theme-one;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: $white-color;

    span {
        position: relative;
        z-index: 1;
    }

    &::before,
    &::after {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        background: $theme-one;
        @include border-radius(50%);
        background: rgba($color:$theme-one, $alpha:.3);
        width: 100%;
        height: 100%;
        animation: pulse 2s linear infinite;
        -webkit-animation: pulse 2s linear infinite;
        -moz-animation: pulse 2s linear infinite;
        opacity: 0;
    }

    &::before {
        animation-delay: 1s;
        -webkit-animation-delay: 1s;
        -moz-animation-delay: 1s;
    }
}

@keyframes pulse {
    0% {
        @include transform(translate(-50%, -50%)scale(1));
        opacity: 1;
    }

    90% {
        opacity: .5;
        @include transform(translate(-50%, -50%)scale(1.8));
    }

    100% {
        opacity: 0;
        @include transform(translate(-50%, -50%)scale(2));
    }
}

/*Shop Section Starts Here*/
.mb-widget {
    margin-bottom: 40px;

    @include breakpoint(md) {
        margin-bottom: 50px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.cart-top {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    background: $white-color;

    table {
        margin-bottom: 40px;
        font-weight: 400;
        width: 100%;
    }

    @include breakpoint(max-md) {
        overflow-x: auto;

        table {
            width: 720px;
        }
    }
}

.cart-top table {
    thead {
        tr {
            th {
                background: $theme-color;
                color: #fff;
                padding: 18px 30px;
                border: none;

                &:nth-child(3) {
                    text-align: center;
                }

                &:last-child {
                    text-align: center;
                    @include border-radius(0 10px 0 0);
                }

                &:first-child {
                    @include border-radius(10px 0 0 0);
                }
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #f7fafd;
            border-right: none;
            border-left: none;
            border-top: none;

            &:last-child {
                border-bottom: none;
            }

            td {
                padding: 15px;
                border: none;

                &:last-child {
                    text-align: center;
                }

                @include breakpoint(md) {
                    padding: 20px;
                }

                @include breakpoint(lg) {
                    padding: 30px 20px;
                }
            }
        }
    }
}

.product-item-2 {
    display: flex;
    align-items: center;

    .p-thumb {
        width: 85px;
        height: 85px;
        @extend %flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
        }

        @include breakpoint(max-lg) {
            width: 75px;
        }
    }

    .p-content {
        width: calc(100% - 85px);
        padding-left: 30px;

        @include breakpoint(max-lg) {
            a {
                font-size: 14px;
            }

            padding-left: 20px;
            width: calc(100% - 75px);
        }

        a {
            color: #000000;
            -webkit-transition: all ease 0.3s;
            -moz-transition: all ease 0.3s;
            transition: all ease 0.3s;
            font-weight: 500;

            &:hover {
                color: $theme-color;
            }
        }
    }
}

.cart-checkout-box {
    background: $white-color;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
}

.coupon-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: -10px;

    .coupon {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
        border: 1px solid $border-color;
        margin: 10px;

        input {
            margin-bottom: 0;
            border: none;
            width: 60%;
            padding-left: 10px;
            background: transparent;
            @include border-radius(0 0 3px 3px);

            &[type="submit"] {
                background: $theme-color;
                // margin-left: -10px;
                width: 40%;
                padding-left: 0px;
                font-weight: 500;
                @include border-radius(0 3px 3px 0);
                text-transform: uppercase;
                font-size: 14px;
            }
        }

        @include breakpoint(max-sm) {
            width: 100%;
            justify-content: space-between;
        }

        @include breakpoint(sm) {
            min-width: 340px;
        }

        @media screen and (max-width:399px) {
            box-shadow: none;
            border: none;

            input {
                width: 100%;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
                border: 1px solid $border-color;

                // flex-grow: 1;
                &[type="submit"] {
                    margin-top: 10px;
                    box-shadow: none;
                    padding: 0 25px;
                    width: auto;
                }
            }
        }
    }

    padding: 15px 10px;

    @include breakpoint(sm) {
        padding: 15px 20px;
    }
}

.cart-checkout {
    width: 100%;
    @extend %flex;
    justify-content: space-between;

    @include breakpoint(lg) {
        max-width: 450px;
        text-align: right;
        justify-content: flex-end;
    }

    input {
        color: $title-color;
        width: auto;
        line-height: 40px;
        margin: 10px;
        padding: 0px 30px;
        border: 1px solid rgb(240, 240, 240);
        border-radius: 4px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
        background: $white-color;
        text-transform: uppercase;
        font-size: 14px;
    }

    @include breakpoint(max-sm) {
        margin-top: 10px;

        input {
            margin: 5px 10px;
        }
    }
}

.shiping-box {
    padding: 30px 20px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
}

.shiping-box .title {
    margin-bottom: 20px;
    text-transform: capitalize;
    padding-bottom: 15px;
    border-bottom: 1px solid #e5ecf6;
}

@media (max-width: 767px) {
    .calculate-shiping {
        margin-bottom: 30px;
    }
}

.calculate-form {
    @extend %flex;
    justify-content: space-between;

    .outline-select {
        width: calc(50% - 15px);
        height: 50px;
        margin-bottom: 20px;

        &:nth-child(1) {
            z-index: 9;
        }

        &:nth-child(2) {
            z-index: 8;
        }

        &:nth-child(3) {
            z-index: 7;
        }
    }

    input {
        border-style: solid;
        border-width: 1px;
        border-color: rgb(240, 240, 240);
        border-radius: 3px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
        width: calc(50% - 15px);
        padding: 10px 20px;
        font-size: 14px;
        margin-bottom: 20px;
    }

    @media screen and (max-width:399px) {

        input,
        .outline-select {
            width: 100%;
        }
    }

    .select-bar {
        width: 100%;
        z-index: 1;
        position: relative;
        height: 50px;
        border: 1px solid rgb(240, 240, 240);
        border-radius: 3px;
        background-color: $white-color;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

        &::after {
            border-color: $title-color;
            right: 20px;
        }

        .current {
            line-height: 50px;
        }

        .list {
            max-height: 200px;
            width: 100%;
            overflow-y: auto;
            z-index: 999;
        }
    }

    .custom-button {
        span {
            line-height: 50px;
        }

        @include breakpoint(sm) {
            margin-top: 10px;
        }
    }
}

.cart-overview ul {
    margin: 0;

    li {
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8px 20px;
        margin-bottom: 15px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
        font-size: 0.875rem;
        @include border-radius(3px);
        border: 1px solid rgb(240, 240, 240);

        .pull-right {
            margin-bottom: 0;
            color: $theme-color;
        }
    }
}

.cart-plus-minus {
    border: 1px solid #dee0e4;
    overflow: hidden;
    width: 120px;
    margin: 0 auto;
    position: relative;

    .dec.qtybutton {
        border-right: 1px solid #dee0e4;
        height: 40px;
        left: 0;
        top: 0;
    }

    .cart-plus-minus-box {
        background: transparent none repeat scroll 0 0;
        border: medium none;
        color: #454545;
        float: left;
        font-size: 14px;
        height: 40px;
        margin: 0;
        padding: 0 9px 0 0;
        text-align: center;
        width: 120px;
        outline: none;
    }

    .qtybutton {
        color: #15273e;
        cursor: pointer;
        float: inherit;
        font-size: 14px;
        font-weight: 500;
        line-height: 40px;
        margin: 0;
        position: absolute;
        text-align: center;
        transition: all 0.3s ease 0s;
        width: 40px;

        &:hover {
            background: $theme-color;
            color: $white-color;
        }

        &.inc {
            border-left: 1px solid #dee0e4;
            height: 40px;
            right: 0;
            top: 0;
        }
    }
}

/*Shop Suru hoiche EKhane*/
.shop-title {
    background-color: #ffffff;
    padding: 16px 20px;
    margin-bottom: 30px;

    @include breakpoint(max-sm) {
        font-size: 14px;
    }

    p {
        margin-bottom: 0;
        color: #000000;
    }

    .product-view-mode a {
        color: #000000;
        cursor: pointer;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        transition: all ease 0.3s;

        &.active {
            color: $theme-color;
        }

        &:last-child {
            margin-left: 5px;
        }
    }
}

.shop-product-wrap.grid .product-list-item {
    display: none;
}

.shop-product-wrap.list div[class*="col"] {
    flex: 0 0 100%;
    max-width: 100%;
}

.shop-product-wrap.list .shop-item {
    display: none;
}

.shop-item,
.product-list-item {
    padding: 10px;
    margin-bottom: 30px;
    display: block;
    align-items: center;
    background-color: $white-color;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

    .product-thumb {
        position: relative;
        overflow: hidden;
        padding: 30px;
        height: 250px;
        background-color: rgb(255, 245, 253);
        text-align: center;

        img {
            height: 100%;
            text-align: center;
        }

        .product-action-link {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            position: absolute;
            left: 0;
            bottom: -70px;
            width: 100%;
            background: rgba($color: $theme-color, $alpha:.8);
            padding: 5px;
            -webkit-transition: all ease 0.3s;
            -moz-transition: all ease 0.3s;
            transition: all ease 0.3s;

            a {
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                border-radius: 3px;
                background-color: #fff;
                color: #696969;
                border: 1px solid transparent;
                -webkit-transition: all ease 0.3s;
                -moz-transition: all ease 0.3s;
                transition: all ease 0.3s;

                &.view-modal {
                    cursor: pointer;
                }
            }
        }

        @include breakpoint(max-md) {
            max-height: 260px;
            padding: 30px;
            text-align: center;

            img {
                width: unset;
                max-width: 100%;
                max-height: 200px;
            }
        }
    }

    .product-content {
        padding-top: 20px;
        text-align: center;

        .title {
            margin: 0;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 5px;

            a {
                &:hover {
                    color: $theme-color;
                }
            }
        }

        p {
            margin-bottom: 10px;
            font-size: 14px;

            i {
                color: $star;
            }
        }

        .subtitle {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    &:hover {
        .product-thumb {
            .product-action-link {
                bottom: 0;
            }
        }
    }
}

div[class*="col"] {
    &:nth-of-type(6n + 1) {

        .shop-item,
        .product-list-item {
            .product-thumb {
                background-color: rgb(255, 245, 253);
            }
        }
    }

    &:nth-of-type(6n + 2) {

        .shop-item,
        .product-list-item {
            .product-thumb {
                background-color: rgb(255, 254, 232);
            }
        }
    }

    &:nth-of-type(6n + 3) {

        .shop-item,
        .product-list-item {
            .product-thumb {
                background-color: rgb(248, 244, 255);
            }
        }
    }

    &:nth-of-type(6n + 4) {

        .shop-item,
        .product-list-item {
            .product-thumb {
                background-color: rgb(242, 255, 235);
            }
        }
    }

    &:nth-of-type(6n + 5) {

        .shop-item,
        .product-list-item {
            .product-thumb {
                background-color: rgb(234, 242, 255);
            }
        }
    }

    &:nth-of-type(6n + 6) {

        .shop-item,
        .product-list-item {
            .product-thumb {
                background-color: rgb(238, 250, 255);
            }
        }
    }
}

.product-list-item {
    display: flex;
    flex-wrap: wrap;

    .product-thumb,
    .product-content {
        width: 100%;
    }

    .product-content {
        padding: 25px 0;
        text-align: left;

        .title {
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 5px;
        }

        .subtitle {
            margin-bottom: 15px;
        }
    }

    @include breakpoint(sm) {
        padding: 30px;
    }

    @include breakpoint(md) {
        padding: 10px;

        .product-thumb {
            width: 35%;
            height: 100%;

            img {
                width: 100%;
                height: unset;
            }
        }

        .product-content {
            padding: 20px 20px 20px 30px;
            width: 65%;
        }
    }
}

.modal {
    display: block;
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        visibility: visible;
        pointer-events: visible;
        z-index: 99999999;
        -webkit-animation: slideInDown 0.4s forwards;
        animation: slideInDown 0.4s forwards;
        overflow-y: scroll;
        background: #060000b0;
    }

    .close {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 2px;
        background: transparent;
        z-index: 9;
        color: #0071f0;
        text-shadow: none;
        opacity: 1;

        &:hover {
            color: $theme-color;
        }
    }

    .modal-dialog {
        .modal-content {
            .modal-body {
                background: #f6faf9;
                border-radius: 8px;
            }
        }
    }
}

.product-details-inner {
    .slider-for .thumb img {
        width: 100%;
    }

    .slider-nav {
        margin-top: 10px;

        .thumb {
            padding: 5px 3px;
        }
    }

    .product-content {
        margin-top: 30px;

        @include breakpoint(lg) {
            margin-top: 0;
        }

        h5 {
            font-size: 20px;

            a {
                color: $title-color;
            }
        }

        h6 {
            font-size: 18px;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 25px;

            i {
                color: $star;
            }
        }
    }
}

.cart-button-2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .cart-plus-minus {
        margin: 0;
    }

    button {
        background-color: $theme-color;
        color: #ffffff;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border: none;

        i {
            color: #ffffff;
        }
    }
}

.product-details-inner {
    background-color: #ffffff;
    padding: 20px;
    overflow: hidden;

    @include breakpoint(max-sm) {
        padding: 20px 15px;
    }
}

.slider-for {
    position: relative;

    .thumb {
        position: relative;
        background: #f8f4ff;

        img {
            max-width: 100%;
        }

        .img-magnifier-glass {
            position: absolute;
            border-radius: 50%;
            cursor: none;
            width: 100px;
            height: 100px;
            border: 1px solid #0071f0;
            z-index: 9;
            opacity: 0;
        }

        &:hover .img-magnifier-glass {
            opacity: 1;
        }
    }
}

.slider-nav {
    margin-top: 20px;

    .thumb {
        height: 60px;
        background: #ebfaff;
        margin: 1px;
        padding: 5px;
        cursor: pointer;
        opacity: .9;
        @extend %flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.slick-next,
.slick-prev {
    &::before {
        font-weight: 700;
    }
}

.product-details {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    background: $white-color;

    .product-single-content {
        .title {
            margin: 0;
            margin-bottom: 8px;
            font-size: 24px;

            a {
                color: $title-color;
                -webkit-transition: all ease 0.3s;
                -moz-transition: all ease 0.3s;
                transition: all ease 0.3s;
                text-transform: capitalize;

                &:hover {
                    color: $theme-color;
                }
            }
        }

        p {
            margin-bottom: 0;

            &.rating {
                margin-bottom: 10px;

                i {
                    color: $star;
                }
            }
        }

        .subtitle,
        .amount {
            text-transform: capitalize;
            font-size: 18px;
            margin-bottom: 5px;
        }

        .amount {
            margin-bottom: 24px;
        }

        .size-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-top: 25px;

            .select-product {
                position: relative;
                background-color: #ffffff;
                margin-bottom: 30px;
                font-size: 0.875rem;
                width: calc(50% - 10px);
                height: 42px;
                margin-bottom: 20px;

                .select-bar {
                    height: 42px;
                    width: 100%;
                    border-color: #f2f2f2;

                    &::after {
                        border-color: $title-color;
                    }
                }

                .list {
                    width: 100%;
                    max-height: 180px;
                    overflow-y: auto;

                    li {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }

            .discount-code {
                width: calc(100% - 140px);
                margin-bottom: 20px;

                input {
                    border: 1px solid #f2f2f2;
                    height: 42px;
                    background: transparent;
                }
            }

            .cart-plus-minus {
                margin: 0;
                margin-bottom: 20px;

                .qtybutton {
                    background: #f8fbff;

                    &:hover {
                        background: $theme-color;
                        border-color: $theme-color;
                    }
                }
            }

            .custom-button {
                margin-top: 15px;
                display: block;
                width: 100%;
                margin-bottom: 5px;

                span {
                    line-height: 50px;
                }
            }
        }
    }

    .product-single-thumb {
        overflow: hidden;

        @include breakpoint(max-md) {
            margin-bottom: 30px;
        }

        .slider-for {
            .thumb {
                outline: none;
                padding: 50px 30px;

                img {
                    max-height: 100%;
                    margin: 0 auto;
                }

                @include breakpoint(max-sm) {
                    padding: 30px;
                }
            }
        }
    }
}

.review-nav {
    margin: 0;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;

    li {
        list-style: none;
        padding: 13px 25px;
        background: $theme-color;
        font-size: 14px;
        font-weight: 500;
        color: $white-color;
        margin-right: 10px;
        cursor: pointer;

        @include breakpoint(sm) {
            font-size: 16px;
            padding: 13px 33px;
        }

        &.active {
            background-color: $white-color;
            color: #000000;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.review-content.review-content-show .review-showing {
    display: block;
}

.review-content.description-show .description {
    display: block;
}

.review-content .review-showing {
    display: none;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;

    .content {
        margin: 0;
        margin-bottom: 30px;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

        li {
            padding: 20px 15px;
            list-style: none;
            border-bottom: 1px solid #ebebeb;

            @include breakpoint(sm) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                padding: 25px 30px;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.rev-thumb-2 {
    margin: 0 auto 15px;
    border-radius: 50%;
    overflow: hidden;
    width: 70px;
    margin-left: 0;

    @include breakpoint(md) {
        margin: 0;
    }

    img {
        max-width: 100%;
    }
}

.rev-content-2 {
    width: 100%;

    @include breakpoint(md) {
        width: calc(100% - 70px);
        padding-left: 20px;
    }

    .entry-meta {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .posted-on {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 110px);

            a {
                padding-right: 20px;
                color: $title-color;
                text-decoration: none;
                box-shadow: none;
                line-height: 28px;
                text-transform: uppercase;
                font-weight: 500;
            }

            p {
                margin-bottom: 0;
                line-height: 30px;
            }

            .rating {
                width: 110px;

                i {
                    color: $star;
                }
            }
        }

        p {
            margin-bottom: 0;
        }
    }
}

.client-review {
    background-color: $white-color;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

    .review-title {
        padding: 15px 30px;
        border-bottom: 1px solid #e5ecff;

        .title {
            margin: 0;
            text-transform: capitalize;
        }
    }

    .review-form .client-form {
        padding: 10px;
        align-items: center;

        @include breakpoint(sm) {
            padding: 30px;
        }

        div[class*="col"] {
            margin-bottom: 30px;
        }

        input {
            border-color: #e5ecff;
            background: transparent;
            height: 55px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            padding-left: 15px;
        }

        .rating {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .rating-title {
                padding-right: 5px;
            }

            ul {
                margin: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    list-style: none;

                    i {
                        color: $star;
                    }
                }
            }
        }

        textarea {
            border-color: #ece5ff;
            padding: 15px;
            height: 130px;
        }

        button {
            display: block;
            width: auto;

            span {
                line-height: 50px;
            }
        }
    }
}

.review-content {
    .description {
        background-color: $white-color;
        padding: 30px;
        display: none;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        border-radius: 10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    }
}

.rev-item .rev-content {
    padding: 30px 0;

    ul {
        margin: 0;

        li {
            list-style: none;
            position: relative;
            padding-left: 30px;

            &::before {
                position: absolute;
                content: "\f005";
                top: 50%;
                left: 0;
                color: $star;
                transform: translateY(-50%);
                font-family: 'Font Awesome 5 Free';
            }
        }
    }
}

/*Error Section Starts Here*/
.min-height-section {
    padding: 150px 0;
    min-height: 100vh;
    @extend %flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include breakpoint(lg) {
        padding: 90px 0;
    }

    @include breakpoint(xxl) {
        padding: 150px 0;
    }

    &::before {
        @extend %pa;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba($color:$title-color, $alpha:.8);
        display: none;
    }

    >* {
        position: relative;
        z-index: 1;
    }

    @include breakpoint(max-xl) {
        &::before {
            display: block;
        }

        p {
            color: rgba($color:$white-color, $alpha:.9);
        }
    }
}

.inner-logo {
    max-width: 220px;
    margin: 0 auto 80px;

    a {
        display: block;
    }

    img {
        width: 100%;
    }

    @include breakpoint(max-xl) {
        margin-bottom: 50px;
    }

    @include breakpoint(max-md) {
        max-width: 190px;
        margin-bottom: 40px;
    }

    @include breakpoint(max-sm) {
        max-width: 140px;
    }
}

.error-wrapper {
    text-align: center;

    .main-thumb {
        max-width: 505px;
        margin: 0 auto 40px;

        img {
            width: 100%;
        }

        @include breakpoint(max-md) {
            max-width: 400px
        }
    }

    .error-content {
        max-width: 500px;
        margin: 0 auto;

        .title {
            color: #192c9d;
            margin-bottom: 6px;

            @include breakpoint(max-xl) {
                color: $white-color;
            }
        }

        p {
            margin-bottom: 28px;
        }
    }
}

/*Coming Soon Section Starts Here*/
.coming-wrapper {
    text-align: center;

    .coming-soon-title {
        font-size: 100px;
        line-height: 1;
        color: #1b3099;

        @media screen and (min-width:1919px) {
            font-size: 140px;
        }

        @include breakpoint(max-xl) {
            font-size: 90px;
            color: $white-color;
        }

        @include breakpoint(max-lg) {
            font-size: 80px;
        }

        @include breakpoint(max-md) {
            font-size: 60px;
        }

        @include breakpoint(max-sm) {
            font-size: 40px;
        }
    }

    .inner-logo {
        margin-bottom: 60px;

        @include breakpoint(xl) {
            margin-bottom: 60px;
        }
    }
}

.coming-countdown {
    @extend %flex;
    justify-content: center;
    margin: 0 auto 27px;

    li {
        padding: 0 30px;
        margin-bottom: 30px;

        .count-title {
            line-height: 1;
            font-size: 72px;
            margin: 0;
            margin-bottom: 15px;
            font-weight: 700;
        }

        p {
            font-size: 24px;
            font-weight: 500;
            margin: 0;
        }

        .days_text,
        .days {
            color: $theme-two;
        }

        .hours_text,
        .hours {
            color: $theme-color;
        }

        .minu_text,
        .minutes {
            color: $theme-one;
        }

        .seco_text,
        .seconds {
            color: $theme-four;
        }
    }

    @include breakpoint(max-xl) {
        li {
            .count-title {
                font-size: 60px;
                margin-bottom: 5px;
            }

            p {
                font-size: 20px;
            }
        }
    }

    @include breakpoint(max-lg) {
        li {
            .count-title {
                font-size: 40px;
                margin-bottom: 5px;
            }

            p {
                font-size: 18px;
                font-weight: 400;
            }
        }
    }

    @include breakpoint(max-sm) {
        li {
            flex-grow: 1;

            .count-title {
                font-size: 30px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}