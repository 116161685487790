/*Animation Starts Here*/
@keyframes outer-ripple {
    0% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      -webkit-filter: alpha(opacity=50); }
    80% {
      transform: scale(1.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      -o-transform: scale(1.5); }
    100% {
      transform: scale(2.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(2.5);
      -moz-transform: scale(2.5);
      -ms-transform: scale(2.5);
      -o-transform: scale(2.5); } }
  @-webkit-keyframes outer-ripple {
    0% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    80% {
      transform: scale(2.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(2.5);
      -moz-transform: scale(2.5);
      -ms-transform: scale(2.5);
      -o-transform: scale(2.5); }
    100% {
      transform: scale(3.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(3.5);
      -moz-transform: scale(3.5);
      -ms-transform: scale(3.5);
      -o-transform: scale(3.5); } }
  @-moz-keyframes outer-ripple {
    0% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    80% {
      transform: scale(2.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(2.5);
      -moz-transform: scale(2.5);
      -ms-transform: scale(2.5);
      -o-transform: scale(2.5); }
    100% {
      transform: scale(3.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(3.5);
      -moz-transform: scale(3.5);
      -ms-transform: scale(3.5);
      -o-transform: scale(3.5); } }
  /* inner ripple */
  @keyframes inner-ripple {
    0% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    30% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    100% {
      transform: scale(1.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      -o-transform: scale(1.5); } }
  @-webkit-keyframes inner-ripple {
    0% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    30% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    100% {
      transform: scale(1.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      -o-transform: scale(1.5); } }
  @-moz-keyframes inner-ripple {
    0% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    30% {
      transform: scale(1);
      filter: alpha(opacity=50);
      opacity: 0.5;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    100% {
      transform: scale(1.5);
      filter: alpha(opacity=0);
      opacity: 0;
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      -o-transform: scale(1.5); } }


@keyframes banner-imgskew {
  from {
    transform: translateY(-30px);
    opacity: 1;
    transition: all .3s ease;
  }
  to {
    transform: translateY(0px);
    opacity: 0;
  }
}