
.video-button {
    display: block;
    position: relative;
    z-index: 1; 
    width: 60px;
    height: 60px;
    line-height: 60px;
    padding: 0;
    background: $white-color;
    text-align: center;
    font-size: 24px;
    border-radius: 50%;
    color: $theme-color;
    &:hover {
        color: $theme-color;
    }
}
@media (min-width: 576px) {
    .video-button {
        width: 92px;
        height: 92px;
        line-height: 88px;
        font-size: 30px;
        border: 2px solid #cfd0fe;
    } 
}
.video-button::before, .video-button::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: $white-color;
    opacity: 0.15;
    z-index: -10; 
}
.video-button::before {
    z-index: -10;
    -webkit-animation: inner-ripple 2000ms linear infinite;
    -moz-animation: inner-ripple 2000ms linear infinite;
    animation: inner-ripple 2000ms linear infinite; 
}
.video-button::after {
    z-index: -10;
    -webkit-animation: outer-ripple 2000ms linear infinite;
    -moz-animation: outer-ripple 2000ms linear infinite;
    animation: outer-ripple 2000ms linear infinite; 
}


.overlayer {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    background: $theme-color;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .loader {
        display: inline-block;
        width: 45px;
        height: 45px;
        position: absolute;
        z-index: 3;
        border: 4px solid #fff;
        top: 50%;
        -webkit-animation: loader 2.5s infinite ease;
        animation: loader 2.5s infinite ease;
        .loader-inner {
            vertical-align: top;
            display: inline-block;
            width: 100%;
            background-color: #fff;
            -webkit-animation: loader-inner 2.5s infinite ease-in;
            animation: loader-inner 2.5s infinite ease-in;
        }
    }
}


@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    75% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    75% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}



.scrollToTop {
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: $white-color;
    z-index: 999;
    bottom: 3px;
    right: 3px;
    position: fixed;
    border-radius: 5px;
    -webkit-transform: translateY(150px);
    -ms-transform: translateY(150px);
    transform: translateY(150px);
    background: $theme-color;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    @include transition($transition);
    @include breakpoint(sm) {
        bottom: 30px;
        right: 30px;
    }
}

.scrollToTop.active {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    animation: bounceInDown 2s;
    -webkit-animation: bounceInDown 2s;
    -moz-animation: bounceInDown 2s;
}

.scrollToTop img {
    width: 100%;
}
.load-more {
    margin-top: 40px;
    text-align: center;
    @include breakpoint(md) {
        margin-top: 65px;
    }
}
.custom-button {
    position: relative;
    color: $white-color;
    border-radius: 4px;
    width: auto;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    outline: none;
    span {
        z-index: 4;
        position: relative;
        display: block;
        line-height: 48px;
        padding: 0 30px;
        @include breakpoint(max-sm) {
            line-height: 45px;
        }
    }
    &::before {
        position: absolute;
        content: "";
        border-radius: 4px;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        background: $theme-color;
        z-index: 3;
    }
    &::after {
        position: absolute;
        content: "";
        background: $button-bg;
        border-radius: 4px;
        height: 100%;
        width: 100%;
        bottom: -4px;
        left: 0;
        @include transition($transition);
    }
    &.theme-one {
        &::before {
            background: $theme-one;
        }
        &::after {
            background: #d24f11;
        }
    }
    &:hover {
        color: $white-color;
        &::after {
            bottom: 0;
        }
    }
}

.RevActive .desc, .DescActive .rev {
    background-color: $white-color !important;
    color: $theme-color !important;
}

.gridActive .grid, .listActive .list {
    color: $theme-color !important;
}