html {
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: $body-color;
    line-height: $lh-p;
    overflow-x: hidden;
    font-family: $body;
    background: $body-bg;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    margin: 0;
    line-height: 1.3;
    color: $title-color;
    font-family: $body;

    a {
        color: $title-color;

        &:hover {
            color: $theme-color;
        }
    }
}

h1 {
    font-size: 40px
}

h2 {
    font-size: 36px;
    margin-top: -9px;
}

h3 {
    font-size: 26px;
    margin-top: -8px;
}

h4 {
    font-size: 20px;
    margin-top: -5px;
}

h5 {
    font-size: 18px;
    margin-top: -4px;
}

h6 {
    font-size: 16px;
    margin-top: -3px;
}

p {
    &:last-child {
        margin-bottom: -7px !important;
    }
}

@include breakpoint (sm) {
    h1 {
        font-size: 48px
    }

    h2 {
        font-size: 40px;
        margin-top: -11px;
    }

    h3 {
        font-size: 30px;
        margin-top: -9px;
    }

    h4 {
        font-size: 24px;
        margin-top: -7px;
    }

    h5 {
        font-size: 22px;
        margin-top: -5px;
    }

    h6 {
        font-size: 16px;
    }
}

@include breakpoint (md) {
    h1 {
        font-size: 80px;
    }

    h2 {
        font-size: 50px;
        line-height: 60px;

        @include breakpoint(lg) {
            font-size: 70px;
            line-height: 87px;
            margin-top: -18px;
        }
    }

    h3 {
        font-size: 36px;
        margin-top: -9px;
    }

    h4 {
        font-size: 24px;
        margin-top: -8px;
    }

    h5 {
        font-size: 20px;
        margin-top: -7px;
    }

    h6 {
        font-size: 16px;
    }
}

a {
    display: inline-block;
    @include transition($transition);
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        padding: 5px 0;
    }
}

select,
input,
textarea,
button {
    width: 100%;
    background-color: $white-color;
    border: 1px solid $border-color;
    outline: none;
}

textarea {
    height: 200px;
    resize: none;
    padding: 10px;
}

input,
button,
select {
    height: 50px;
    color: $body-color;
}

label,
button,
select {
    cursor: pointer;
}

input {
    background: #f4f4f4;
    border: 1px solid transparent;
    @include border-radius(5px);
    padding-left: 10px;

    &:focus {
        border: 1px solid $theme-one;
    }

    &[type="submit"] {
        cursor: pointer;
        background-color: $primary-color;
        color: $white-color;
        font-weight: 700;
        padding: 0;
    }
}

.bg_img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.bg_contain {
    background-size: contain;
}

.bg_fixed {
    background-attachment: fixed;
}

.top_center {
    background-position: top center;
}

.bottom_center {
    background-position: bottom center;
}

.right_center {
    background-position: right center;
}

.left_center {
    background-position: left center;
}

@include breakpoint(sm) {
    .-mx-10 {
        margin-left: -10px;
        margin-right: -10px;
    }
}