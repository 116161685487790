	/*
  	Flaticon icon font: Flaticon
    Creation date: 23/05/2022 22:32
    Font generated by <a href="https://www.flaticon.com">flaticon.com</a>. <p>Under <a href="http://creativecommons.org/licenses/by/3.0/">CC</a>: <a data-file="021-envelope" href="https://www.flaticon.com/authors/those-icons">Those Icons</a>, <a data-file="002-play" href="https://www.flaticon.com/authors/pixel-perfect">Pixel perfect</a>, <a data-file="024-pin" href="http://www.freepik.com">Freepik</a>, <a data-file="015-content-management-system" href="https://www.flaticon.com/authors/eucalyp">Eucalyp</a>, <a data-file="023-phone-call" href="https://www.flaticon.com/authors/smashicons">Smashicons</a>, <a data-file="006-full-screen" href="https://www.flaticon.com/authors/smartline">Smartline</a>, <a data-file="008-presentation" href="https://www.flaticon.com/authors/nhor-phai">Nhor Phai</a>, <a data-file="009-deposit" href="https://www.flaticon.com/authors/pixelmeetup">Pixelmeetup</a>, <a data-file="010-atm-machine" href="https://www.flaticon.com/authors/good-ware">Good Ware</a>, <a data-file="012-bars" href="https://www.flaticon.com/authors/prosymbols">Prosymbols</a>, <a data-file="013-deposit-1" href="https://www.flaticon.com/authors/ultimatearm">ultimatearm</a>, <a data-file="025-clock" href="https://www.flaticon.com/authors/surang">surang</a>, <a data-file="026-add" href="https://www.flaticon.com/authors/xnimrodx">xnimrodx</a></p>  
     
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-search:before { content: "\f100"; }
.flaticon-play:before { content: "\f101"; }
.flaticon-reliability:before { content: "\f102"; }
.flaticon-compensation:before { content: "\f103"; }
.flaticon-vegan:before { content: "\f104"; }
.flaticon-full-screen:before { content: "\f105"; }
.flaticon-maximize:before { content: "\f106"; }
.flaticon-presentation:before { content: "\f107"; }
.flaticon-deposit:before { content: "\f108"; }
.flaticon-atm-machine:before { content: "\f109"; }
.flaticon-charity:before { content: "\f10a"; }
.flaticon-bars:before { content: "\f10b"; }
.flaticon-deposit-1:before { content: "\f10c"; }
.flaticon-content-management-system:before { content: "\f10d"; }
.flaticon-settings:before { content: "\f10e"; }
.flaticon-referral:before { content: "\f10f"; }
.flaticon-zoom:before { content: "\f110"; }
.flaticon-logout:before { content: "\f111"; }
.flaticon-bell:before { content: "\f112"; }
.flaticon-envelope:before { content: "\f113"; }
.flaticon-email:before { content: "\f114"; }
.flaticon-phone-call:before { content: "\f115"; }
.flaticon-pin:before { content: "\f116"; }
.flaticon-clock:before { content: "\f117"; }
.flaticon-add:before { content: "\f118"; }